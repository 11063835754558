import React from 'react';
import './swiper.css';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper';
import projectMiniImg from '../../assets/img/project_mini_mockup.webp';
import zooMarketImg from '../../assets/img/zoo_market_mockup.webp';

const SwiperComponent = () => {
	return (
		<Swiper
			navigation={true}
			initialSlide={0}
			pagination={true}
			modules={[Pagination, Navigation]}
		>
			<SwiperSlide>
				<img
					src={zooMarketImg}
					alt='zoo market page'
				/>
				<div className='slide-desc'>
					<h2>Zoo Market</h2>
					<h3>Family run pet food shop</h3>
					<a
						aria-label='project website link'
						href='http://www.zoomarket.lt'
						target='_blank'
						rel='noreferrer'
					>
						Visit website
					</a>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<img
					src={projectMiniImg}
					alt='project mini page'
				/>
				<div className='slide-desc'>
					<h2>Project MINI</h2>
					<h3>Manufacturer of modular houses</h3>
					<a
						aria-label='project website link'
						href='http://www.projectmini.lt'
						target='_blank'
						rel='noreferrer'
					>
						Visit website
					</a>
				</div>
			</SwiperSlide>
		</Swiper>
	);
};

export default SwiperComponent;
