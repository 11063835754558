import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Switch from './Switch/Switch';
import './footer.css';
import {BsFillTelephoneFill} from 'react-icons/bs';
import {GoMail} from 'react-icons/go';
import {GoMarkGithub} from 'react-icons/go';
import {setFormDescErrorMsg, setFormEmailErrorMsg, setFormNameErrorMsg, setFormNumberErrorMsg, setShowCalendar, setShowFormSuccess, setShowSendButton} from '../../store/generalStore';
import DateTimePicker from './DateTimePicker/DateTimePicker';
import emailjs from '@emailjs/browser';

function Footer() {
	const {showSendButton, formDescErrorMsg, formNumberErrorMsg, formEmailErrorMsg, formNameErrorMsg, showCalendar, showFormSuccess} = useSelector((state) => state.generalSlice);
	const [value, setValue] = useState(false);
	const dispatch = useDispatch();
	const form = useRef();
	const nameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const descRef = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();
		const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		const phonePattern = /^\+\d{1,15}$/;
		const firstNamePattern = /^[a-zA-Z]+$/;

		const dataToSend = {
			firstName: nameRef.current.value,
			email: emailRef.current.value,
			phoneNumber: phoneRef.current.value,
			description: descRef.current.value,
		};

		const isValidEmail = regex.test(dataToSend.email);
		const isValidNumber = phonePattern.test(dataToSend.phoneNumber);
		const isValidName = firstNamePattern.test(dataToSend.firstName);

		if (!isValidEmail) return dispatch(setFormEmailErrorMsg('Enter a valid email address.'));
		if (!isValidNumber) return dispatch(setFormNumberErrorMsg('Enter a valid phone number.'));
		if (!isValidName) return dispatch(setFormNameErrorMsg('Enter a valid name.'));
		if (dataToSend.description.length > 200 || dataToSend.description.length < 3) return dispatch(setFormDescErrorMsg('Message length incorrect.'));

		emailjs.sendForm('service_u8qrnpb', 'template_f04i4eq', form.current, '87rR3tuwUnFE8tJIf').then(
			(result) => {
				dispatch(setShowSendButton(false));
				dispatch(setFormEmailErrorMsg(null));
				dispatch(setFormNumberErrorMsg(null));
				dispatch(setFormNameErrorMsg(null));
				dispatch(setFormDescErrorMsg(null));
				dispatch(setShowFormSuccess(!showFormSuccess));
			},
			(error) => {
				console.log(error.text);
			}
		);

		nameRef.current.value = '';
		emailRef.current.value = '';
		phoneRef.current.value = '';
		descRef.current.value = '';
	};

	const handleShowSend = () => {
		if (nameRef.current.value !== '' && emailRef.current.value !== '' && phoneRef.current.value !== '' && descRef.current.value !== '') {
			dispatch(setShowSendButton(true));
		} else {
			dispatch(setShowSendButton(false));
		}
	};

	useEffect(() => {
		setTimeout(() => {
			dispatch(setShowFormSuccess(false));
		}, 5000);
	}, [showFormSuccess, dispatch]);

	useEffect(() => {
		if (showCalendar) dispatch(setShowSendButton(false));
	}, [showCalendar, dispatch]);

	return (
		<div className='contacts container'>
			<div className='contacts-container'>
				<div className='contacts-contacts'>
					<h2 className='text-flicker-in-glow'>Contact us</h2>
					<div className='email-phone'>
						<div>
							<GoMail className='contacts-icon' />
							<a
								aria-label='remodal contact email'
								target='_blank'
								rel='noreferrer'
								href='https://mail.google.com/mail/u/1/?view=cm&fs=1&to=remodal.contacts@gmail.com&tf=1'
							>
								contact@remodal.lt
							</a>
						</div>
						<div>
							<BsFillTelephoneFill className='contacts-icon' />
							<a
								aria-label='remodal contact phone number'
								href='tel:+37061000571'
							>
								+370 610 00571
							</a>
						</div>
					</div>
					<div className='contacts-location'>
						<img
							src={require('../../assets/img/map.webp')}
							alt=''
						/>
						<p>Vilnius, Lithuania</p>
					</div>
				</div>
				<div className='contacts-form-container'>
					<div className='contacts-form'>
						<div className='switch-container'>
							<Switch
								onColor='#D9D9D9'
								isOn={value}
								handleToggle={() => {
									setValue(!value);
									dispatch(setShowCalendar(!showCalendar));
								}}
							/>
						</div>
						{showCalendar ? (
							<div className='date-time-wrapper'>
								<DateTimePicker />
							</div>
						) : (
							<form
								ref={form}
								onSubmit={(e) => handleSubmit(e)}
							>
								{formNameErrorMsg ? (
									<label
										className='form-error first-label'
										htmlFor='First name'
									>
										{formNameErrorMsg}
									</label>
								) : (
									<label htmlFor='First name'>First name</label>
								)}
								<input
									aria-label='first name'
									autoComplete='off'
									onChange={() => {
										handleShowSend();
										dispatch(setFormNameErrorMsg(null));
									}}
									ref={nameRef}
									type='text'
									name='user_name'
								/>
								{formEmailErrorMsg ? (
									<label
										className='form-error'
										htmlFor='Email'
									>
										{formEmailErrorMsg}
									</label>
								) : (
									<label htmlFor='Email'>E-mail address</label>
								)}
								<input
									aria-label='email'
									autoComplete='off'
									onChange={() => {
										handleShowSend();
										dispatch(setFormEmailErrorMsg(null));
									}}
									ref={emailRef}
									type='text'
									name='user_email'
								/>
								{formNumberErrorMsg ? (
									<label
										className='form-error'
										htmlFor='Phone number'
									>
										{formNumberErrorMsg}
									</label>
								) : (
									<label htmlFor='Phone number'>Phone number</label>
								)}
								<input
									aria-label='phone number'
									autoComplete='off'
									onChange={() => {
										handleShowSend();
										dispatch(setFormNumberErrorMsg(null));
									}}
									ref={phoneRef}
									type='text'
									name='user_phone'
									placeholder='+3706*******'
								/>
								{formDescErrorMsg ? (
									<label
										className='form-error'
										htmlFor='Description'
									>
										{formDescErrorMsg}
									</label>
								) : (
									<label htmlFor='Description'>Brief description</label>
								)}
								<input
									aria-label='message'
									autoComplete='off'
									onChange={() => {
										handleShowSend();
										dispatch(setFormDescErrorMsg(null));
									}}
									ref={descRef}
									type='text'
									name='user_message'
								/>
							</form>
						)}
					</div>
					{!showCalendar && (
						<div>
							{showFormSuccess ? (
								<button className='send-form-button send-form-btn-overlay send-form-left-port-btn form-success'>Form sent</button>
							) : (
								<button
									className={`${showSendButton ? 'send-form-button send-form-btn-overlay send-form-left-port-btn' : 'hide-send-button'}`}
									onClick={handleSubmit}
								>
									Send
								</button>
							)}
						</div>
					)}
				</div>
			</div>
			{/* <div className='contacts-hr'>
				<p>
					Responsive web development for businesses and personalities <br />{' '}
					Individual design approach made simple
				</p>
				<a
					aria-label='remodal github page link'
					href='https://github.com/MBDigitalCursor'
					target='_blank'
					rel='noreferrer'
				>
					<GoMarkGithub />
				</a>
			</div> */}
		</div>
	);
}

export default Footer;
