import React from 'react';
import {BsLinkedin, BsGithub} from 'react-icons/bs';
import './about.css';
import ArturasImg from '../../assets/img/Artur.webp';
import TonyImg from '../../assets/img/Anton.webp';
import IgnasImg from '../../assets/img/Ignas.webp';
import RokasImg from '../../assets/img/Rokas.webp';

function About() {
	const teamMembers = [
		{
			img: ArturasImg,
			role: 'Developer',
			name: 'Artūr Januška',
			about: 'Solver of logical tasks and issues for projects we put our hands on. Crafts the back end to provide team with fitting solutions.',
			linkedin: '/in/artur-januska-11449a258/',
			github: '/Mixmln',
		},
		{
			img: TonyImg,
			role: 'Designer/Developer',
			name: 'Anton Polujanov',
			about: 'Versatile creator of design with dev skills to create responsive, functional and user friendly websites, software and applications.',
			linkedin: '/in/anton-tonis-polujanov-40062b271/',
			github: '/tonispagonis',
		},
		{
			img: RokasImg,
			role: 'Developer',
			name: 'Rokas Markvaldas',
			about: 'Pillar behind our strategic approach to work. Master organizer of assignments, conducts team performance for timely execution. ',
			linkedin: '/in/rokas-markvaldas-208766271/',
			github: '/RokasMark',
		},
		{
			img: IgnasImg,
			role: 'Developer',
			name: 'Ignas Venckūnas',
			about: 'Craftsman of front end implementation. Shapes raw ideas into functional components for immaculate user experience.',
			linkedin: '/in/ignas-venckunas/',
			github: '/ivenckunas',
		},
	];

	return (
		<div className='about container'>
			<h2>Our band</h2>
			<div className='about-cards'>
				{teamMembers.map((member, i) => (
					<div
						key={i}
						className='single-about-card'
					>
						<img
							src={member.img}
							alt='team member'
						/>
						<div className='single-card-desc'>
							<h3 style={{fontSize: '1rem'}}>{member.role}</h3>
							<h4>{member.name}</h4>
							<p>{member.about}</p>

							<div className='about-socials'>
								<a
									aria-label='remodal member social link'
									href={`https://www.linkedin.com${member.linkedin}`}
									target='_blank'
									rel='noreferrer'
								>
									<BsLinkedin className='about-socials-icon' />
								</a>
								<a
									aria-label='remodal member social link'
									href={`https://github.com${member.github}`}
									target='_blank'
									rel='noreferrer'
								>
									<BsGithub className='about-socials-icon' />
								</a>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default About;
